<template>
<div v-if="status == 'Pending'">
    <div class="wrapper">
      <ul class="StepProgress">
        <li class="StepProgress-item is-done">
          <strong>Activation Form information added </strong>
        </li>
        <li class="StepProgress-item is-done"><strong>Document uploaded</strong></li>
        <li class="StepProgress-item"><strong>PSP Approved</strong></li>
        <li class="StepProgress-item">
          <strong>Live API credentials Shared</strong>
        </li>
        <li class="StepProgress-item"><strong>Keys Added</strong></li>
        <li class="StepProgress-item"><strong>Gone Live</strong></li>
      </ul>
    </div>
</div>
<div v-else>




  <div v-if="this.formvalue == 0">
    <div class="wrapper">
      <ul class="StepProgress">
        <li class="StepProgress-item">
          <strong>Activation Form information added </strong>
        </li>
        <li class="StepProgress-item"><strong>Document uploaded</strong></li>
        <li class="StepProgress-item"><strong>PSP Approved</strong></li>
        <li class="StepProgress-item">
          <strong>Live API credentials Shared</strong>
        </li>
        <li class="StepProgress-item"><strong>Keys Added</strong></li>
        <li class="StepProgress-item"><strong>Gone Live</strong></li>
      </ul>
    </div>

    <div class="text-left">
      <button @click="handlenext" class="btn btn-success dark-bg mt-4 col-4">
        Next
      </button>
    </div>
  </div>

  <div v-else-if="formvalue == 1">
    
        <b-alert
        variant="danger"
        dismissible
        fade
        :show="showDismissibleAlert"
        @dismissed="showDismissibleAlert=false"
        >
        {{ this.$store.state.notification.message }}
        </b-alert>
    

    <form id="onboarding-form" v-on:submit.prevent="next" novalidate>
      <!-- <div class="text-center"><h6> <span><img src="@/assets/images/gbppicon.svg" width="50" alt="logo" /> </span>&nbsp; &nbsp;{{ channelName.channelName }} ONBOARDING FORM </h6></div> -->
      <div class="row">
        <div
          :key="formData.fieldName"
          v-for="formData in this.formdata"
          class="col-12"
        >
          <div
            v-if="formData.fieldName == 'password'"
            class="col-12 form-group"
          >
            <label class="col-form-label col-form-label-sm">
              {{
                formData.displayName
              }}
              <img
                src="@/assets/images/tooltip.jpg"
                alt="tooltip"
                height="20"
                data-toggle="tooltip"
                data-placement="top"
                :title="formData.description"
              />
              <span class="text-danger">* </span>
            </label>
            <input
              type="password"
              class="form-control form-control-md"
              v-model.trim="formData.value"
              :minlength="formData.minLength"
              @input="validateform(formData.value, formData.fieldName)"
              pattern="(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{8,}"
              required
            />
            <div style="color: red" v-if="!formvalidation[formData.fieldName]"> Required</div>
            <div style="color: red" v-else-if="!validatePassword">Password must contain upper lower character, at least 1 numeric and 1 special character and length should be atleast 8 characters</div>
            <!-- <div v-if="!$v.email.required" class="invalid-feedback"> The {{ formData.fieldName }}  is required. </div> -->
          </div>

          <div
            v-else-if="formData.type == 'dropdown'"
            class="col-12 form-group"
          >
            <label class="col-form-label col-form-label-sm">
              {{
                formData.displayName
              }}
              <img
                src="@/assets/images/tooltip.jpg"
                alt="tooltip"
                height="20"
                data-toggle="tooltip"
                data-placement="top"
                :title="formData.description"
              />
              <span class="text-danger">* </span>
            </label>

            <select
              class="form-control form-control-md"
              v-model.trim="formData.value"
              @change="validateform(formData.value, formData.fieldName)"
              required
            >
              <option value="">Please select an option</option>
              <option
                :key="option.name"
                v-for="option in formData.dropdownOptions"
                :value="option.value"
              >
                {{ option.name }}</option
              >
            </select>
            <div style="color: red" v-if="!formvalidation[formData.fieldName]"> Required</div>
            <!-- <b-form-select v-model.trim="formData.value" :options="options" class="mb-3">
                    <b-form-select-option :value="null">Please select an option</b-form-select-option>
                    <b-form-select-option :key="option.name" v-for="option in formData.dropdownOptions" :value="option.value">{{ option.name }}</b-form-select-option>
                </b-form-select> -->

            <!-- <div v-if="!$v.email.required" class="invalid-feedback"> The {{ formData.fieldName }}  is required. </div> -->
          </div>

          <div
            v-else-if="formData.dataType === 'text' && !formData.mandatory"
            class="col-12 form-group"
          >
            <label class="col-form-label col-form-label-sm">
              {{
                formData.displayName
              }}
              <img
                src="@/assets/images/tooltip.jpg"
                alt="tooltip"
                height="20"
                data-toggle="tooltip"
                data-placement="top"
                :title="formData.description"
              />
            </label>
            <input
              :type="formData.dataType"
              v-model.trim="formData.value"
              class="form-control form-control-md"
            />
            <!-- <div v-if="!$v.email.required" class="invalid-feedback"> The {{ formData.fieldName }}  is required. </div> -->
          </div>
          <div
            v-else-if="formData.dataType === 'date'"
            class="col-12 form-group"
          >
            <label class="col-form-label col-form-label-sm">
              {{
                formData.displayName
              }}
              <img
                src="@/assets/images/tooltip.jpg"
                alt="tooltip"
                height="20"
                data-toggle="tooltip"
                data-placement="top"
                :title="formData.description"
              />
              <!-- <span class="text-danger">* </span> -->
            </label>
            <input
              :type="formData.dataType"
              v-model.trim="formData.value"
              class="form-control form-control-md"
            />
            <!-- <div v-if="!$v.email.required" class="invalid-feedback"> The {{ formData.fieldName }}  is required. </div> -->
          </div>

          <div
            v-else-if="formData.dataType === 'url' && !formData.mandatory"
            class="col-12 form-group"
          >
            <label class="col-form-label col-form-label-sm">
              {{
                formData.displayName
              }}
              <img
                src="@/assets/images/tooltip.jpg"
                alt="tooltip"
                height="20"
                data-toggle="tooltip"
                data-placement="top"
                :title="formData.description"
              />
            </label>
            <input
              :type="formData.dataType"
              v-model.trim="formData.value"
              @input="validateurl(formData.value, formData.fieldName)"
              class="form-control form-control-md"
            />
            <div style="color: red" v-if="!validateWebUrl && formData.value !=='' && formData.fieldName === 'merchantWebsite' ">Url is not in proper format.</div>
            <div style="color: red" v-else-if="!validateFaceUrl && formData.value !=='' && formData.fieldName === 'merchantFacebook' ">Url is not in proper format.</div>
            <div style="color: red" v-else-if="!validateInstaUrl && formData.value !=='' && formData.fieldName === 'merchantInstagram' ">Url is not in proper format.</div>

            <!-- <div v-if="!$v.email.required" class="invalid-feedback"> The {{ formData.fieldName }}  is required. </div> -->
          </div>

          <div
            v-else-if="formData.dataType !== 'image' && formData.dataType !== 'image_or_pdf'"
            class="col-12 form-group"
          >
            <label class="col-form-label col-form-label-sm">
              {{
                formData.displayName
              }}
              <img
                src="@/assets/images/tooltip.jpg"
                alt="tooltip"
                height="20"
                data-toggle="tooltip"
                data-placement="top"
                :title="formData.description"
              />
              <span class="text-danger">* </span>
            </label>
            <input
              :type="formData.dataType"
              v-model.trim="formData.value"
              @input="validateform(formData.value, formData.fieldName)"
              class="form-control form-control-md"
              required="formData.mendatory"
              :disabled="formData.disabled"
            />
            <div style="color: red" v-if="!formvalidation[formData.fieldName]"> Required</div>
            <!-- <div v-if="!$v.email.required" class="invalid-feedback"> The {{ formData.fieldName }}  is required. </div> -->
          </div>
        </div>
        <div v-if="this.isvalidate && (!this.validateFaceUrl && !this.validateInstaUrl && !this.validateWebUrl)" style="color: red; margin-left: 1.3rem;"> Please fill atleast one of the following field. <br/>1. Merchant Instagram <br/> 2. Merchant Facebook <br/> 3. Merchant Website </div>
        <div v-else> </div>

        <div class="col-12 form-group text-center">
          <button v-if="this.isvalidate && (this.validateFaceUrl || this.validateInstaUrl || this.validateWebUrl) && !this.urlError" class="btn btn-vue btn-md col-4">Save & next</button>
          <button v-else class="btn btn-vue btn-md col-4 disabled">Save & next </button>
        </div>
      </div>
    </form>
  </div>

  <div v-else-if="formvalue == 2">
    
      
      <b-alert
        variant="danger"
        dismissible
        fade
        :show="showDismissibleAlert"
        @dismissed="showDismissibleAlert=false"
        >
        {{ this.$store.state.notification.message }}
        </b-alert>
    

    <form id="onboarding-form-file" v-on:submit.prevent="submit">
      <div class="row">
        <div
          :key="formData.fieldName"
          v-for="formData in this.formdata"
          class="col-12"
        >
          <div
            v-if="formData.dataType == 'image' && formData.mandatory"
            class="col-12 form-group"
          >
            <label class="col-form-label col-form-label-sm">
              {{
                formData.displayName
              }}
              <img
                src="@/assets/images/tooltip.jpg"
                alt="tooltip"
                height="20"
                data-toggle="tooltip"
                data-placement="top"
                :title="formData.description"
              />
              <span class="text-danger">* </span>
            </label>
            <!-- <input type="file" accept="image/jpeg, image/png"  class="form-control form-control-sm" @change=uploadImage(formData.fieldName) required="formData.mendatory"> -->
            <div class="row">
              <div class="col-lg-12 position-relative float-right">
                <input
                  type="file"
                  accept="image/jpeg, image/png"
                  class="form-control file_Upload input-file"
                  @change="uploadImage(formData.fieldName, formData.value)"
                  
                />
                <div class="bulk-upload-button btn-sm">
                  <img
                    style="position: absolute;left: 25px;top: 30px;"
                    src="@/assets/images/upload_icon.svg"
                    class="cursor-pointer"
                    alt
                  />
                  Upload Files Here
                </div>
              </div>
            </div>
            {{ formData.value ? formData.filename : "" }}
            <div
              v-if="formData.value === '' || formData.value == undefined"
              class="media-body-custom pt-3"
              style="border-top: 1px solid #D9D9D9;"
            >
              <img src="@/assets/images/arrow.png" width="20px" alt /> 0 Out Of 0 File
              Uploaded
            </div>
            <div
              v-if="formData.value != '' && formData.value !== undefined"
              class="media-body-custom pt-3"
              style="border-top: 1px solid #D9D9D9;"
            >
              <img src="@/assets/images/tick.png" width="20px" alt /> 1 Out Of 1 File
              Uploaded
            </div>

            <!-- <div v-if="!$v.email.required" class="invalid-feedback"> The {{ formData.fieldName }}  is required. </div> -->
          </div>
          <div
            v-else-if="
              formData.dataType == 'image' && !formData.mandatory
            "
            class="col-12 form-group"
          >
            <label class="col-form-label col-form-label-sm">
              {{
                formData.displayName
              }}
              <img
                src="@/assets/images/tooltip.jpg"
                alt="tooltip"
                height="20"
                data-toggle="tooltip"
                data-placement="top"
                :title="formData.description"
              />
            </label>
            <!-- <input type="file" accept="image/jpeg, image/png"  class="form-control form-control-sm" @change=uploadImage(formData.fieldName)> -->
            <div class="row">
              <div class="col-lg-12 position-relative float-right">
                <input
                  type="file"
                  accept="image/jpeg, image/png"
                  class="form-control file_Upload input-file"
                  @change="uploadImage(formData.fieldName, formData.value)"
                />
                <div class="bulk-upload-button btn-sm">
                  <img
                    style="position: absolute;left: 25px;top: 30px;"
                    src="@/assets/images/upload_icon.svg"
                    class="cursor-pointer"
                    alt
                  />
                  Upload Files Here
                </div>
              </div>
            </div>
            {{ formData.filename }}
            <div
              v-if="formData.value == ''"
              class="media-body-custom pt-3"
              style="border-top: 1px solid #D9D9D9;"
            >
              <img src="@/assets/images/arrow.png" width="20px" alt /> 0 Out Of 0 File
              Uploaded
            </div>
            <div
              v-if="formData.value != ''"
              class="media-body-custom pt-3"
              style="border-top: 1px solid #D9D9D9;"
            >
              <img src="@/assets/images/tick.png" width="20px" alt /> 1 Out Of 1 File
              Uploaded
            </div>

            <!-- <div v-if="!$v.email.required" class="invalid-feedback"> The {{ formData.fieldName }}  is required. </div> -->
          </div>

          <div
            v-else-if="
              formData.dataType == 'image_or_pdf' && formData.mandatory
            "
            class="col-12 form-group"
          >
            <label class="col-form-label col-form-label-sm">
              {{
                formData.displayName
              }}
              <img
                src="@/assets/images/tooltip.jpg"
                alt="tooltip"
                height="20"
                data-toggle="tooltip"
                data-placement="top"
                :title="formData.description"
              />
              <span class="text-danger">* </span>
            </label>
            <!-- <input type="file" accept="image/jpeg, image/png"  class="form-control form-control-sm" @change=uploadImage(formData.fieldName)> -->
            <div class="row">
              <div class="col-lg-12 position-relative float-right">
                <input
                  type="file"
                  accept="image/jpeg, image/png, application/pdf"
                  class="form-control file_Upload input-file"
                  @change="uploadImage(formData.fieldName, formData.value)"
                  
                />
                <div class="bulk-upload-button btn-sm">
                  <img
                    style="position: absolute;left: 25px;top: 30px;"
                    src="@/assets/images/upload_icon.svg"
                    class="cursor-pointer"
                    alt
                  />
                  Upload Files Here
                </div>
              </div>
            </div>
            {{ formData.filename }}
            <div
              v-if="formData.value == ''"
              class="media-body-custom pt-3"
              style="border-top: 1px solid #D9D9D9;"
            >
              <img src="@/assets/images/arrow.png" width="20px" alt /> 0 Out Of 0 File
              Uploaded
            </div>
            <div
              v-if="formData.value != ''"
              class="media-body-custom pt-3"
              style="border-top: 1px solid #D9D9D9;"
            >
              <img src="@/assets/images/tick.png" width="20px" alt /> 1 Out Of 1 File
              Uploaded
            </div>

            <!-- <div v-if="!$v.email.required" class="invalid-feedback"> The {{ formData.fieldName }}  is required. </div> -->
          </div>

          <div
            v-else-if="
              formData.dataType == 'image_or_pdf' && !formData.mandatory && formData.style !== 'disabled'
            "
            class="col-12 form-group"
          >
            <label class="col-form-label col-form-label-sm">
              {{
                formData.displayName
              }}
              <img
                src="@/assets/images/tooltip.jpg"
                alt="tooltip"
                height="20"
                data-toggle="tooltip"
                data-placement="top"
                :title="formData.description"
              />
            </label>
            <!-- <input type="file" accept="image/jpeg, image/png"  class="form-control form-control-sm" @change=uploadImage(formData.fieldName)> -->
            <div class="row">
              <div class="col-lg-12 position-relative float-right">
                <input
                  type="file"
                  accept="image/jpeg, image/png, application/pdf"
                  class="form-control file_Upload input-file"
                  @change="uploadImage(formData.fieldName, formData.value)"
                />
                <div class="bulk-upload-button btn-sm">
                  <img
                    style="position: absolute;left: 25px;top: 30px;"
                    src="@/assets/images/upload_icon.svg"
                    class="cursor-pointer"
                    alt
                  />
                  Upload Files Here
                </div>
              </div>
            </div>
            {{ formData.filename }}
            <div
              v-if="formData.value == ''"
              class="media-body-custom pt-3"
              style="border-top: 1px solid #D9D9D9;"
            >
              <img src="@/assets/images/arrow.png" width="20px" alt /> 0 Out Of 0 File
              Uploaded
            </div>
            <div
              v-if="formData.value != ''"
              class="media-body-custom pt-3"
              style="border-top: 1px solid #D9D9D9;"
            >
              <img src="@/assets/images/tick.png" width="20px" alt /> 1 Out Of 1 File
              Uploaded
            </div>

            <!-- <div v-if="!$v.email.required" class="invalid-feedback"> The {{ formData.fieldName }}  is required. </div> -->
          </div>
          <div
            v-else-if="
              formData.dataType == 'image_or_pdf' && !formData.mandatory && formData.style === 'disabled'
            "
            class="col-12 form-group" style="display:none"
          >
            <label class="col-form-label col-form-label-sm">
              {{
                formData.displayName
              }}
              <img
                src="@/assets/images/tooltip.jpg"
                alt="tooltip"
                height="20"
                data-toggle="tooltip"
                data-placement="top"
                :title="formData.description"
              />
            </label>
            <!-- <input type="file" accept="image/jpeg, image/png"  class="form-control form-control-sm" @change=uploadImage(formData.fieldName)> -->
            <div class="row">
              <div class="col-lg-12 position-relative float-right">
                <input
                  type="file"
                  accept="image/jpeg, image/png, application/pdf"
                  class="form-control file_Upload input-file"
                  @change="uploadImage(formData.fieldName, formData.value)"
                />
                <div class="bulk-upload-button btn-sm">
                  <img
                    style="position: absolute;left: 25px;top: 30px;"
                    src="@/assets/images/upload_icon.svg"
                    class="cursor-pointer"
                    alt
                  />
                  Upload Files Here
                </div>
              </div>
            </div>
            {{ formData.filename }}
            <div
              v-if="formData.value == ''"
              class="media-body-custom pt-3"
              style="border-top: 1px solid #D9D9D9;"
            >
              <img src="@/assets/images/arrow.png" width="20px" alt /> 0 Out Of 0 File
              Uploaded
            </div>
            <div
              v-if="formData.value != ''"
              class="media-body-custom pt-3"
              style="border-top: 1px solid #D9D9D9;"
            >
              <img src="@/assets/images/tick.png" width="20px" alt /> 1 Out Of 1 File
              Uploaded
            </div>

            <!-- <div v-if="!$v.email.required" class="invalid-feedback"> The {{ formData.fieldName }}  is required. </div> -->
          </div>
        </div>

        <div class="col-12 form-group text-center">
          <button v-if="!this.isvalidatefiles" class="btn btn-vue btn-md col-4 disabled" :disabled="this.active"> Submit
          </button>
          <button v-else class="btn btn-vue btn-md col-4" :disabled="this.active">
            Submit
          </button>
        </div>
      </div>
    </form>
  </div>

  <div v-else-if="formvalue == 3">
    <img src="@/assets/images/success.png" alt="success" width="100%" />
    <h6 class="text-center" style="color: green">
      Your account activation request has been successfully submitted.
    </h6>
    <div class="p-3">
      <p>Here are the basic details:</p>
      <p>PSP Name : GBPrimePay</p>
      <p>Country : Thailand</p>
      <p>Request Date : {{ currdate }}</p>
      <p>Activation Request ID : {{ activationId }}</p>
    </div>
    <div class="col-12 form-group text-center">
      <button class="btn btn-vue btn-md col-4" @click="doneOnboarding">
        Done
      </button>
    </div>
  </div>
</div>
</template>

<script>
// import { required, email, maxLength, minLength } from "vuelidate/lib/validators"
import {
  FETCH_ONBOARDING_FORM_DATA,
  ADD_ONBOARDING_FORM_DATA,
} from "@/state/actions.type";
import * as cloneDeep from "lodash.clonedeep";
export const CHAIPAY_API_URL = process.env.VUE_APP_CHAIPAY_BACKEND_URL;

export default {
  name: "onboardingForm",
  props: ["channelName", "formvalue", "formDatas1", "status"],
  data: function() {
    var today = new Date();
    return {
      currdate:
        today.getFullYear() +
        "-" +
        (today.getMonth() + 1) +
        "-" +
        today.getDate(),
      errorMessage: "",
      formdata: [],
      loading: false,
      formvalue1: 1,
      activationId: "",
      showDismissibleAlert:false,
      fileUploadSuccess: false,
      active: false,
      isvalidate: false,
      isvalidatefiles: false,
      validatePassword: true,
      formvalidation:{},
      validateUrl:false,
      validateWebUrl: false,
      validateFaceUrl:false,
      validateInstaUrl:false,
      urlError:false,
      merchant_uuids:""
    };
  },

  // mounted(){

  //     this.formdata = cloneDeep(this.onboardingFormData);

  // },

  computed: {
    onboardingFormData() {
      return this.$store.state.paymentChannels.onboardingFormData.formData.map(
        (val) => {
          val.value =
            this.$store.state.paymentChannels.onboardingFormData.initialValues[
              val.fieldName
            ] == undefined
              ? ""
              : this.$store.state.paymentChannels.onboardingFormData
                  .initialValues[val.fieldName];
          val.disabled =
            this.$store.state.paymentChannels.onboardingFormData.initialValues[
              val.fieldName
            ] == undefined
              ? false
              : true;
          //val.value = "";
          return val;
        }
      );
    },
  },

  methods: {
    submit: function() {
      if(this.isvalidatefiles){
        this.active = true;
        //console.log(this.$store.state.paymentChannels);
        //var reqBody1 = {};
        var formdata1 = new FormData();
        this.formdata.forEach(function(value) {
          //reqBody1[value.fieldName] = value.value;
          if(value.dataType==="date" && value.value !== ""){
            var d = new Date(value.value),
                  month = '' + (d.getMonth() + 1),
                  day = '' + d.getDate(),
                  year = d.getFullYear();
              if (month.length < 2) 
                  month = '0' + month;
              if (day.length < 2) 
                  day = '0' + day;

            let v = [year, month, day].join('');
            formdata1.append(value.fieldName, v)
          }
          else{
            formdata1.append(value.fieldName, value.value)
          }
        });
        //console.log(this.formvalidation);

        this.$store
          .dispatch(`paymentChannels/${ADD_ONBOARDING_FORM_DATA}`, {
            reqBody: formdata1,
          })
          .then((res) => {
            console.log(res);
            this.activationId = res.channelRefrence;
            this.formvalue1 = 3;
            this.active = false;
            this.$emit("onClickNext", {
              formPage: this.formvalue1,
              formDatas: this.formdata,
            });
            //this.onboardingFormData;
            //alert("Your Details are successfully submitted.")
            //setTimeout(window.location.href = `/settings/pay-channel`, 5000);
            //console.log("After computed");
          })
          .catch((err) => {
            console.log(err);
            this.showalert()
            this.active = false;
          });
      }
    },

    showalert(){
        this.showDismissibleAlert=true
    },

    next() {
      
      if(this.isvalidate){
        this.formvalue1 = 2;
        this.$emit("onClickNext", {
          formPage: this.formvalue1,
          formDatas: this.formdata,
        });
      }
      this.formdata.map((val)=> {
        console.log(val);
        if(val.mandatory){
          if(val.value === ""){
            this.isvalidatefiles = false;
          }
        }
      })
      console.log(this.formvalidation);
      

    },
    validateform(value1, fieldname1){
      if(fieldname1 === "merchantType"){
        if(value1 === "I"){
          this.formdata.map((value) => {
            if(value.fieldName === "companyRegistration"){
              value.mandatory = false;
            }
            else if(value.fieldName === "additionalDocument"){
              value.mandatory = false;
              value.style= "disabled";
            }
            else if(value.fieldName === "otherDocument"){
              value.mandatory = true;
              value.style = "";
            }

          })

        }
        else if(value1 === "C"){
          this.formdata.map((value) => {
            if(value.fieldName === "companyRegistration"){
              value.mandatory = true;
            }
            else if(value.fieldName === "additionalDocument"){
              value.mandatory = true;
              value.style = "";
            }
            else if(value.fieldName === "otherDocument"){
              value.mandatory = false;
              value.style= "disabled";
            }

          })

        }
      }
      const PasswordRegex = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/;
      // this.formdata.map((value) => {
      //   if(value.dataType !== "image" && value.dataType !== "image_or_pdf"){
      //     if(value.mandatory ){
      //       this.formvalidation[value.fieldName] = false;
      //       this.isvalidate = false;
      //     }
      //     else{
      //       this.formvalidation[value.fieldName]  = true
      //     }
      //   }
      // })
      if(value1 !== ""){
        if(fieldname1 === "password"){
          this.validatePassword = PasswordRegex.test(value1);
          //console.log(this.validatePassword);
          this.formvalidation[fieldname1] = true;
        }
        else{
        this.formvalidation[fieldname1] = true;
        }
      }
      else{
        this.formvalidation[fieldname1] = false;
      }
      let flag = true;
      this.formdata.map((value) => {
        if(value.dataType !== "image" && value.dataType !== "image_or_pdf"){
          if(value.mandatory && (value.value === "" || value.value === undefined)){
              flag = false
          }
        }
        this.isvalidate = flag;
        if(this.validatePassword == false){
          this.isvalidate = false;
        }
        
      })


    },
    validateurl(value1, fieldname1){
      const UrlRegex = /^(http(s)?:\/\/.)?(www\.)?[-a-zA-Z0-9@:%._+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_+.~#?&=]*)/;
      this.urlError = false;
      if(value1 !== ""){
        if(fieldname1 === "merchantWebsite" || fieldname1 === "merchantFacebook" || fieldname1 === "merchantInstagram" ){
          this.validateUrl = UrlRegex.test(value1);
          //console.log(this.validatePassword);
          this.formvalidation[fieldname1] = true;
          if(fieldname1 === "merchantWebsite"){
            this.validateWebUrl = UrlRegex.test(value1);
            if(!this.validateWebUrl){
              this.urlError = true;
            }
          }
          else if(fieldname1 === "merchantFacebook"){
            this.validateFaceUrl = UrlRegex.test(value1);
            if(!this.validateFaceUrl){
              this.urlError = true;
            }
          }
          else if(fieldname1 === "merchantInstagram"){
            this.validateInstaUrl = UrlRegex.test(value1);
            if(!this.validateInstaUrl){
              this.urlError = true;
            }
          }

        }
        else{
        this.formvalidation[fieldname1] = true;
        }

      }
      else{
        this.formvalidation[fieldname1] = false;
      }
      // let flagUrl = false;
      // this.formdata.map((value) => {
      //   if(value.dataType === "url"){
      //     if(value.value !== ""){
      //         flagUrl = true
      //     }
      //   }
        //this.isvalidate = flagUrl;
        // if(this.validateUrl === false){
        //   //this.isvalidate = false;
        // }
        // console.log(this.validateUrl);
        // console.log(this.isvalidate);
        
      //})
      //console.log(this.formvalidation);

    },
    handlenext() {
      this.formvalue1 = 1;
      this.$emit("onClickNext", {
        formPage: this.formvalue1,
        formDatas: this.formdata,
      });
      ///this.setpage = 1;
    },
    doneOnboarding() {
      window.location.href = `/settings/pay-channel`;
    },

    uploadImage(fieldName, value1) {
      
    if(event.target.files[0] !== undefined){
      const findField = this.formdata.find(
        (fdata) => fdata.fieldName === fieldName
      );
      //console.log(findField);
      const file = event.target.files[0];
      //const reader = new FileReader();
      this.fileUploadSuccess = true;

      // let rawImg;
      // reader.onloadend = () => {
      //   rawImg = reader.result;
      //   console.log(file.name);
      findField.value = file;
      findField.filename = file.name;
      // };
      // reader.readAsDataURL(file);
      //console.log(findField)
    }


      if(event.target.files[0] == undefined){
        this.formvalidation[fieldName] = false;
      }
      else if(value1 === ""){
        this.formvalidation[fieldName] = false;
      }
      else{
        this.formvalidation[fieldName] = true;
      }
      let flag1 = true;
      this.formdata.map((value) => {
        if(value.dataType === "image" || value.dataType === "image_or_pdf"){
          if(value.mandatory && (value.value === "" || value.value === undefined)){
              flag1 = false
          }
        }
        this.isvalidatefiles = flag1;
        
      })
      //console.log(this.formvalidation);

    },
  },
  // watch: {
    
  // },

  created() {
    // const requestOptions = {
    // method: 'GET',
    // headers: { 'Content-Type': 'application/json' },
    // credentials: 'include',
    // };
    var queryParams = new URLSearchParams(window.location.search)
    this.merchant_uuids = queryParams.get("uuid");
    // //const queryParams = new URLSearchParams({environment});
    // return fetch(`${CHAIPAY_API_URL}/onboarding/merchants/cadfc143-a968-41ed-b408-163cb5dffc44/GBPRIMEPAY?environment=sandbox`, requestOptions).then(async response => {
    //     const data = await response.json();

    //     if (!response.ok) {
    //         // get error message from body or default to response statusText
    //         const error = (data && data.message) || response.statusText;
    //         this.errorMessage = error;
    //         return Promise.reject(error);
    //     }

    //     //debugger;
    //     this.formdata = data.formData.map( val =>{
    //         console.log(typeof data.initialValues[val.fieldName]);

    //         val.value = data.initialValues[val.fieldName] == undefined ? "" : data.initialValues[val.fieldName];
    //         val.disabled = data.initialValues[val.fieldName] == undefined ? false : true;
    //         return val

    //     }) ;
    //     })
    //     .catch(error => {
    //     this.errorMessage = error;
    //     console.error("There was an error!", error);
    //     });
    this.loading = true;
    this.$store
      .dispatch(`paymentChannels/${FETCH_ONBOARDING_FORM_DATA}`, 
        {
        merchant_uuids:this.merchant_uuids,
         })
      .then(() => {
        //this.onboardingFormData;
        this.formdata = cloneDeep(this.onboardingFormData);
        this.formdata.map((value) => {
          this.formvalidation[value.fieldName] = true
        })
        this.loading = false;
        console.log(this.formvalidation);
      });
  },
};
</script>
<style scoped>
.btn-vue {
  background: #000;
  color: white;
}

.bulk-upload-button {
  text-align: center;
  color: #b3afaf;
  cursor: pointer;
  border-radius: 5px;
  display: block;
  padding: 15px 10px;
  border: 1px dashed #cbcbcb;
  margin-top: 18px;
}

.cursor-pointer {
  cursor: pointer;
}

.file_Upload {
  opacity: 0;
  height: 65px;
  position: absolute;
  top: 10px;
  left: 0px;
  width: 100%;
  z-index: 1;
  cursor: pointer;
}

.uploads {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
.StepProgress {
  position: relative;
  padding-left: 45px;
  list-style: none;
}
.StepProgress::before {
  display: inline-block;
  content: "";
  position: absolute;
  top: 0;
  left: 15px;
  width: 10px;
  height: 100%;
  border-left: 2px solid #ccc;
}
.StepProgress-item {
  position: relative;
  counter-increment: list;
}
.StepProgress-item:not(:last-child) {
  padding-bottom: 20px;
}
.StepProgress-item::before {
  display: inline-block;
  content: "";
  position: absolute;
  left: -30px;
  height: 100%;
  width: 10px;
}
.StepProgress-item::after {
  content: "";
  display: inline-block;
  color: rgb(248, 212, 8);
  position: absolute;
  top: 0;
  left: -41px;
  width: 25px;
  height: 25px;
  text-align: center;
  border: 2px solid rgb(248, 212, 8);
  border-radius: 50%;
  background-color: #fff;
}
.StepProgress-item.is-done::before {
  border-left: 2px solid green;
}
.StepProgress-item.is-done::after {
  content: "✔";
  font-size: 14px;
  left: -41px;
  width: 25px;
  height: 25px;
  color: #fff;
  text-align: center;
  border: 2px solid green;
  background-color: green;
}
.StepProgress-item.current::before {
  border-left: 2px solid green;
}
.StepProgress-item.current::after {
  content: counter(list);
  padding-top: 1px;
  width: 25px;
  height: 25px;
  top: -4px;
  left: -41px;
  font-size: 14px;
  text-align: center;
  color: green;
  border: 2px solid green;
  background-color: white;
}
.StepProgress strong {
  display: block;
}
.dark-bg {
  background-color: #252b3a;
  border-color: #252b3a;
}
</style>
